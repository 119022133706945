// src/components/TermsAndConditions.js
import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto p-10 mb-6 mt-6" id="Terms">
      <h1 className="text-3xl font-bold mb-4">Conditions Générales d'Utilisation</h1>

      <h2 className="text-2xl font-bold mt-6 mb-2">1. Introduction</h2>
      <p>
        Bienvenue chez taxiparis-reservezvotrevtc.fr ! Ces conditions générales d'utilisation définissent les règles et règlements relatifs à l'utilisation de notre site web et de nos services. En accédant à ce site, vous acceptez de vous conformer à ces conditions. Si vous n'êtes pas d'accord avec une partie de ces conditions, veuillez ne pas utiliser notre site web.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">2. Propriété Intellectuelle</h2>
      <p>
        Tout le contenu, y compris, sans s'y limiter, les textes, images, graphiques et logos, est la propriété de taxiparis-reservezvotrevtc.fr ou de ses concédants. Vous n'êtes pas autorisé à reproduire, dupliquer ou exploiter tout matériel de ce site sans permission explicite.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">3. Obligations de l'utilisateur</h2>
      <p>
        En utilisant nos services, vous acceptez de fournir des informations exactes, actuelles et complètes lors de la réservation ou de l'inscription. Vous êtes responsable de la confidentialité de vos informations de compte et de toutes les activités effectuées sous votre compte.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">4. Limitation de Responsabilité</h2>
      <p>
        taxiparis-reservezvotrevtc.fr ne pourra être tenue responsable des dommages indirects, accessoires, spéciaux ou consécutifs découlant de l'utilisation ou de l'incapacité à utiliser nos services. Cela inclut, mais sans s'y limiter, la perte de profits, de données ou d'opportunités commerciales.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">5. Résiliation</h2>
      <p>
        Nous nous réservons le droit de suspendre ou de résilier immédiatement votre accès à nos services, sans préavis, en cas de violation des présentes conditions générales.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">6. Droit Applicable</h2>
      <p>
        Ces conditions générales sont régies et interprétées conformément aux lois du Royaume-Uni. Tout litige découlant de l'utilisation de ce site sera soumis à la juridiction exclusive des tribunaux du Royaume-Uni.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">7. Modifications des Conditions</h2>
      <p>
        Nous nous réservons le droit de réviser et de mettre à jour ces conditions générales à tout moment. Les modifications seront publiées sur cette page, et il est de votre responsabilité de les consulter régulièrement.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">8. Contactez-nous</h2>
      <p>
        Si vous avez des questions ou des préoccupations concernant ces conditions, veuillez nous contacter à l'adresse suivante : <a href="mailto:contact@taxiparis-reservationvtc.com" className="text-blue-500">contact@taxiparis-reservationvtc.com</a>.
      </p>
    </div>
  );
};

export default TermsAndConditions;
