const ModalSucess = ({ open_modal, setOpenModal }) => {
  const open = open_modal;

  const modal_jsx = (
    <>
      {/* Overlay */}
      <div className="modal-overlay"></div>

      {/* Modal */}
      <div
        tabIndex={-1}
        className="fixed inset-0 flex justify-center z-50 h-full overflow-y-auto overflow-x-hidden"
      >
        <div className="relative p-4 top-20 w-full h-full max-w-md">
          <div className="border border-gray-300 relative bg-white rounded-lg shadow dark:bg-white-700">
            <button
              type="button"
              className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="popup-modal"
              onClick={() => setOpenModal(false)}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="p-4 md:p-5 text-center">
              <div className="flex justify-center items-center bg-green-100 rounded-full w-12 h-12 mx-auto mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-green-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <h3 className="text-lg font-normal">
                Réservation envoyée avec succès
              </h3>
              <h4 className="text-lg font-normal text-gray-500 dark:text-gray-400">
                Vous allez être recontacté sous peu !
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  if (open) return modal_jsx;

  return <></>;
};

export default ModalSucess;
