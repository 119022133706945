import React from 'react';

import { FaPhoneAlt } from 'react-icons/fa';

const ContactUs = () => {
  return (
    <div className="py-16 text-center" id="Contact">
      {/* Title */}
      <h2 className="text-4xl font-bold px-[1rem] md:px-20 lg:px-20">
        N'attendez pas — <span className="text-red-500">Contactez-nous </span>dès aujourd'hui !
      </h2>

      {/* Description */}
      <p className="mt-4 text-gray-600 px-20 px-[2rem] md:px-20 lg:px-20">
        Vous avez besoin de plus d’informations sur nos services ou vous êtes prêt à réserver votre trajet ? <br></br> N’hésitez pas, contactez-nous et nous serons heureux de vous aider dans vos déplacements parisiens !
      </p>

      <div className="flex justify-center mt-8">
        <a
          href="tel:0185091394"
          className="w-60 rounded-full mx-8 bg-red-600 text-white font-bold text-xl font-sans p-3 rounded flex items-center justify-center hover:bg-red-700 transition duration-300 ease-in-out"
        >
          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" className="mr-2 text-3x1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>
          01 85 09 13 94
        </a>
      </div>
    </div>
  );
};

export default ContactUs;
