const ModalBadAddress = ({ open_modalBadAddress, setOpenModalBadAddress, message }) => {
    const open = open_modalBadAddress;

    const modal_jsx = (
        <>
            {/* Fond gris (overlay) */}
            <div
                className="modal-overlay"
                onClick={() => setOpenModalBadAddress(false)} // Ferme le modal quand on clique sur l'overlay
            ></div>

            {/* Modal */}
            <div
                tabIndex={-1}
                className="fixed inset-0 flex justify-center z-50 h-full overflow-y-auto overflow-x-hidden"
            >
                <div className="relative p-4 top-20 w-full h-full max-w-md z-60">
                    <div className="border border-gray-300 relative bg-white rounded-lg shadow dark:bg-white-700">
                        <button
                            type="button"
                            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            onClick={() => setOpenModalBadAddress(false)}
                        >
                            <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Fermer</span>
                        </button>
                        <div className="p-4 md:p-5 text-center">
                            <div className="flex justify-center items-center bg-red-100 rounded-full w-12 h-12 mx-auto mb-4">
                                <span
                                    className="d-inline-block text-white rounded-full"
                                    style={{
                                        backgroundColor: '#EA5455', // Rouge pour l'erreur
                                        width: '50px',
                                        height: '50px',
                                        lineHeight: '50px',
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* Croix avec '✗' */}
                                    ✗
                                </span>
                            </div>
                            <h4 className="text-red-600">{message}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    if (open) return modal_jsx;

    return null;
};

export default ModalBadAddress;
